// components
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/abx-header-image.jpg";
import Dollar from "../images/confi/dollar.svg";
import Globe from "../images/confi/globe.svg";
import Handshake from "../images/confi/handshake.svg";
import CheckIcon from "../images/icon/check.svg";
import Facts from "../images/confi/location.svg";
import Size from "../images/confi/size.svg";
import Profile from "../images/confi/profile.svg";
import Foursource from "../images/confi/foursource.svg";
import Apparel from "../images/confi/apparel.svg";
import ApparelManufacturer from "../images/confi/apparelManufacturer.svg";
import Banner from "../images/confi/banner.svg";
/*import gasbxpersona from "../images/caseStudies/avatar.png";*/


export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("asbx.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("asbx.subTitle"),
        colorClass: "colorLight",
      },
      /*cta: {
        text: t("asbx.topCta"),
        colorClass: "colorLight",
        url: t("asbx.ctaUrl"),
        type: "mainColor",
      },*/
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("asbx.imgAlt"),
      },
    },
    topIcons: [
      {
        title: {
          text: t("asbx.newMarketsValue"),
          textBefore: "",
          isNum: true,
        },
        subtitle: {
          text: t("asbx.newMarketsSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Globe,
          alt: t("asbx.newMarketsAlt"),
        },
      },
      {
        title: {
          text: t("asbx.roiValue"),
          textBefore: "",
          textAfter: "",
          isNum: true,
        },
        subtitle: {
          text: t("asbx.roiSubtitle"),
          colorClass: "colorDark",
        },
        icon: {
          src: Dollar,
          alt: t("asbx.roiAlt"),
        },
      },
      {
        title: {
          text: t("asbx.recruitingValue"),
          textBefore: "",
          textAfter: " x",
          isNum: true,
        },
        subtitle: {
          text: t("asbx.recruitingSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Handshake,
          alt: t("asbx.recruitingAlt"),
        },
      },
    ],
    sideIcons1: [
      {
        title: {
          text: t("asbx.locationTitle"),
        },
        subtitle: {
          text: t("asbx.locationSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Facts,
          alt: t("asbx.locationAlt"),
        },
      },
      {
        title: {
          text: t("asbx.productTitle"),
        },
        subtitle: {
          text: t("asbx.productSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Apparel,
          alt: t("asbx.productAlt"),
        },
      },
      {
        title: {
          text: t("asbx.memberTitle"),
        },
        subtitle: {
          text: t("asbx.memberSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: ApparelManufacturer,
          alt: t("asbx.memberAlt"),
        },
      },
      {
        title: {
          text: t("asbx.membershipTitle"),
        },
        subtitle: {
          text: t("asbx.membershipSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Profile,
          alt: t("asbx.membershipAlt"),
        },
      },
      {
        title: {
          text: t("asbx.sizeTitle"),
        },
        subtitle: {
          text: t("asbx.sizeSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Size,
          alt: t("asbx.sizeAlt"),
        },
      },
      {
        title: {
          text: t("asbx.sinceTitle"),
        },
        subtitle: {
          text: t("asbx.sinceSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Foursource,
          alt: t("asbx.sinceAlt"),
        },
      },
    ],
    sideIcons2: [
      {
        title: {
          text: t("asbx.challengesSquareTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("asbx.challengesSquareSubtitle"),
          colorClass: "colorDark",
          textSize: "font17  pt-10"
        },
        icon: {
          src: '',
          alt: t("asbx.newMarketsAlt"),
        },
      },
    ],
    sideIcons3: [
      {
        title: {
          text: t("asbx.favoriteToolsTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("asbx.favoriteToolsSubtitle"),
          colorClass: "colorDark",
          textSize: " font17 pt-20"
        },
        icon: {
          src: '',
          alt: t("asbx.favoriteToolsAlt"),
        },
        banner: true,
        bannerSrc: Banner,
        bannerAlt: "Banner"
      },
    ],
    /*gasbxpersona: [
      {
        title: {
          text: t("asbx.gasbxpersonaName"),
        },
        subtitle: {
          text: t("asbx.gasbxpersonaTitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: gasbxpersona,
          alt: t("asbx.newMarketsAlt"),
        },
      },
    ],*/
    aboutInfo: {
      title: {
        text: t("asbx.aboutTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("asbx.aboutText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    challengesInfo: {
      title: {
        text: t("asbx.challengesTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("asbx.challengesText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    findingInfo: {
      title: {
        text: t("asbx.findingTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("asbx.findingText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    resultsInfo: {
      title: {
        text: t("asbx.resultsTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("asbx.resultsText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    bullet: {
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
      separator: false,
      item: {
        text: [
          t("asbx.bullet1"),
          t("asbx.bullet2"),
          t("asbx.bullet3"),
          t("asbx.bullet4"),
        ],
        colorClass: "colorDark",
      },
      customPBText: true,
    },
    bullet2: {
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
      separator: false,
      item: {
        text: [
          t("asbx.bullet5"),
          t("asbx.bullet6"),
          t("asbx.bullet7"),
        ],
        colorClass: "colorDark",
      },
      customPBText: true,
    },
    bottomText:  [
      {
        text: t("asbx.bulletBottomText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    cta: {
      text: t("asbx.cta1"),
      url: "https://foursource.com/apparel-manufacturers/",
      type: "whiteColor",
    },
    cta2: {
      text: t("asbx.cta2"),
      url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
    },
    squareText: t("asbx.challengesSquareSubtitle2"),
    favoriteToolsExtra: t("asbx.favoriteToolsExtra"),
    profileText: t("asbx.profileText"),
    /*testimonyName: t("asbx.gasbxpersonaName"),
    testimonyTitle: t("asbx.gasbxpersonaTitle"),
    anchorText: t("asbx.anchorText")*/
  };
};
